.services{
    width: 100%;
    
    background-color:#292826 ;
    padding-top: 17vh;
    
}

.services-body{
    height: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
   padding-bottom: 3rem;
   padding-top: 3rem;
}

.services-body h1{
    /* background-color: blue; */
    
}

.services-cards{
 display: flex;
 flex-wrap: wrap;
 margin-top: 5vh;

}

.service_icon{
    height: 5rem;
    width: 5rem;
    padding-bottom: 1rem;
}

.card{
    height: auto;
   
    width: 25vw;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 1rem;
    border-radius: 2rem;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

}

.card h2{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
}
.card p{
    font-size: 1rem;
    text-align: center;
}
.card:hover{
    transform: scale(1.1);
}

@media (max-width: 580px) {
    .services-body{
        height: auto;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 2rem;
        padding: 2rem;
    }
    .services-cards{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 10vh;
       }
       
       .card{
           height: 60vh;
           width: 70vw;
           background-color: rgba(0, 0, 0, 0.3);
           margin: 0rem;
           border-radius: 2rem;
           transition: transform 0.3s ease;
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           padding: 1rem;
           margin-bottom: 2rem;
       }
       
       .card h2{
           font-size: 1.5rem;
           margin-bottom: 1rem;
           text-align: center;
       }
       .card p{
           font-size: 1rem;
           text-align: center;
       }
    
  
  } 

  @media (min-width: 580px)and(max-width:1150px) {
   
    .services{
        width: 100%;
        
        background-color:#292826 ;
        padding-top: 10vh;
        
    }
    
  } 
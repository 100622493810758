.about{
    width: 100%;
    padding: 10vh;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-radius: 40px; */
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 0.3);
    transition: box-shadow 0.5s ease-in-out;
}
.about:hover{
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
}

.about p{
    font-size: 1.2rem;
    line-height:2rem;text-align: center;
   margin: 5rem 10rem 5rem 10rem;
}

.title{
   color: #F7D060;
   font-size: 2.5rem;
}

.about-container{
    background-color: #292826;
    padding: 17vh 0px;
    
}


@media (max-width: 580px) {
  
    .about{
        width: 100%;
      padding: 0px;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border-radius: 40px; */
        box-shadow: 0px 0px 0px rgba(255, 255, 255, 0.3);
        transition: box-shadow 0.5s ease-in-out;
    }
    .about:hover{
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
    }
    
    .about p{
        font-size: 1rem;
        line-height:1.5rem;text-align: center;
       /* margin: 5rem 5rem 5rem 5rem; */
       margin: 1.5rem;
    }
    
    .title{
       
        font-size: 1.4rem;
        margin-top: 10px;
        color:#F7D060 ;
    }
    
    .about-container{
        background-color: #292826;
       
        
    }
    
  
  } 


  @media (min-width: 580px)and(max-width:1150px){
    .about{
        width: 100%;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border-radius: 40px; */
        box-shadow: 0px 0px 0px rgba(255, 255, 255, 0.3);
        transition: box-shadow 0.5s ease-in-out;
    }
    .about:hover{
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
    }
    
    .about p{
        font-size: 1.2rem;
        line-height:2rem;text-align: center;
       margin: 5rem 5rem 5rem 5rem;
    }
    
    .title{
       
        font-size: 2rem;
        margin-top: 2rem;
        color:#F7D060 ;
    }
    
    .about-container{
        background-color: #292826;
        padding: 10vh 0px;
        
    }
    
  }
.header{
    display: flex;
    width: 100%;
    height: 16vh;
    background-color: #292826;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: height 1s ease, background-color 1s ease;
   z-index: 100;
   
}
.header.scrolled{
    height: 12vh;
    background-color: #292826;
    
}



.header.scrolled::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 0.5px;
    background-color: #F7D060;
    animation-name: border-animation;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  @keyframes border-animation {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  


.highlight{
    color: #b80b0b;
}

.header-logo{
 height: 4rem;
 margin-left: 20px;
 margin-top: 0.1rem;
}



.nav-bar{
    display: flex;
    margin-right: 20px;
    
}

.nav-bar li{
    list-style: none;
}

.nav-item{
    list-style: none;
    padding: 0px 30px;
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    
  
    position: relative;
}

.nav-bar-show li{
  display: none;
}

.nav-item.selected{
  color: #F7D060;
}

.nav-item.unselected{
  color: white;
}

.nav-item-resume{
    list-style: none;
    padding: 0px 30px;
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: #F7D060;
    padding: 8px 20px;
    border-radius: 10px;
    position: relative;
    margin-left: 1rem;
    color:#292826 ;
    transition: all 0.5s ease;
}

.nav-item-resume:hover{
  color: #F7D060;
  background-color: #292826;
  border: solid 1px #F7D060;
  
}

.nav-item:hover{
    color: #F7D060;
    transition: all .4s ease-in-out;

}

.menu-icon{
  height: 2rem;
  width: 2rem;
  color: #F7D060;
  margin: 0px 30px 5px;
  display: none;
}

@media (max-width: 1150px) {
  .nav-bar{
    display: none;
   
    
  }

  .nav-bar-show{
    display: flex;
    flex-direction: column;
   background-color: #292826;
    justify-content: start;
    align-items: center;
    width: 100vw;
    padding-bottom: 3vh;
    padding-top: 10vh;
    height: 100vh;
    position: absolute;
    top: 0px;
    z-index: 200;
    transition: transform 0.3s ease;
    padding-left: 0px;
  }

  .nav-bar-show li{
    list-style: none;
    margin: 2vh;
    display: flex;
}

.nav-item{
    list-style: none;
   
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    
  
    position: relative;
}

.nav-item.selected{
  color: #F7D060;
}

.nav-item.unselected{
  color: white;
}

.nav-item-resume{
    list-style: none;
    
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: #F7D060;
 
    border-radius: 10px;
    position: relative;
   
    color:#292826 ;
}



.nav-item:hover{
    color: #F7D060;
    transition: all .4s ease-in-out;

}

  .menu-icon{
    z-index: 300;
    display: block;
  
  }

  

}  






.project-details-body{
    min-height: 100vh;
    width: 100%;
    background-color: #292826;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.project-details-head{
    display: flex;
  align-items: center;
  justify-content: center;
    width: auto;
   /* background-color: #F7D060; */
    height:60vh;
    margin-top: 17vh;
}
.project-details-title h1{
    margin-bottom: 2rem;
}

.decorate-txt.github:hover{
 cursor: pointer;

}




.project-description{
    font-size: 1rem;
}

.spinner{
    height: 100vh;
    width: 100%;
    background-color: #292826;
    position: absolute;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

.timeline-tech p{
  font-size: 1.2rem;
  /* margin-bottom: 0.5rem; */
}

.timeline-tech{
display: block;
}

.decorate-txt{
    color: #F7D060;
}

.image-caraousal{
    height: 60vh;
    width: 50vw;
}



.project-details-title{
    width: auto;
    height: 100%;
    color: white;
    margin-left: 2rem;
   float: left;
    
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}


.project-details-description{
    margin-top: 4rem;
    width:80vw;
    height: 30%;
    
    display: flex;
    justify-content: center;
    align-items: start;
}

.img-container{
    height: 80vh;
  
  object-fit: contain;
  max-width: 60vw;
  display: flex;
  margin: 0 auto;
  
}

.container{
    background-color: #292826;
    padding: 0px 30px;
}

.carousel-image{
    width: 25vw;
    float: right;
    height: 100%;
  
   margin-top: 2rem;
}

.project-cover{
    
  width: auto;
   height: 100%;
   object-fit: contain;
   margin: 0 auto;
   border-radius: 1rem;
   
}

.paragrah{
    padding: 5rem 0rem;
    
}

.project-details-description p{
    padding: 0.5rem 2rem;
    color: white;
    font-size: 1.2rem;
    word-spacing: 0.4rem;
    line-height: 1.5rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}



@media (max-width: 580px) {

    .project-details-body{
        min-height: 100vh;
        width: 100%;
        background-color: #292826;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .project-details-head{
        display: flex;
        flex-direction: column;
      align-items: center;
      justify-content: center;
        width: auto;
       /* background-color: #F7D060; */
        height:60vh;
        margin-top: 17vh;
    }
    .project-details-title h1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 1.5rem;
    }
    
    .timeline-tech p{
      font-size: 1rem;
      /* margin-bottom: 0.5rem; */
    }
    
    .timeline-tech{
    display: block;
    }
    
    .decorate-txt{
        color: #F7D060;
    }
    
    .image-caraousal{
        height: 60vh;
        width: 50vw;
    }
    
    
    
    .project-details-title{
        width: auto;
        height: 100%;
        color: white;
        margin-left: 2rem;
       float: left;
        
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    
    
    .project-details-description{
        margin-top: 1rem;
        width:100vw;
       
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .project-details-description p{
        text-align: justify;
        
    }
    
    .img-container{
        height: auto;
      
      object-fit: contain;
      max-width: 60vw;
      display: flex;
      margin: 0px;
      
    }
    .paragrah{
        padding: 0rem 0rem 2rem 0rem;
    }
    
    .container{
        background-color: #292826;
        padding: 0px 30px;
    }
    
    .carousel-image{
        width: 100vw;
        float: right;
        height: 100%;
      
       margin-top: 0rem;
    }
    
    .project-cover{
        
      width: 90vw;
       height: 100%;
       object-fit: contain;
       margin: 0 auto;
       border-radius: 1rem;
       
    }
    
    .project-details-description p{
        padding: 0.5rem 2rem;
        color: white;
        font-size: 1.2rem;
        word-spacing: 0.4rem;
        line-height: 1.5rem;
        text-align: start;
    }
  
  } 

  @media (max-width:1150px){
    .project-details-head{
        display: flex;
        flex-direction: column;
      align-items: center;
      justify-content: center;
        width: auto;
       /* background-color: #F7D060; */
        height:60vh;
        margin-top: 20vh;
        margin-bottom: 2rem;
    }

    .project-cover{
        
        width: 90vw;
         height: 100%;
         object-fit: contain;
         margin: 0 auto;
         border-radius: 1rem;
         
      }
  }
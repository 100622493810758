.contact-body{
    padding-bottom: 17vh;
    background-color: #292826;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 17vh;
    
}
.fiverr-icon:hover{
  transform: scale(1.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

textarea { resize: none; }

.contact-body h1{
  margin-bottom: 2rem;
  color: #F7D060;
}
.contact-form {
  width: 70vw;
  height: 60vh;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  position: relative;
}

#message{
    width: 61vw;
    margin: 2rem 0.5rem 1rem 0.5rem;
    height: 100%;
    border: none;
    background-color: transparent;
  border: 1px solid rgba(247, 208, 96, 0.6);
 border-radius: 1rem;
  
  padding: 1rem;
  font-size: 1.5rem;
  outline: none;
  color: white;
}

#message:focus{
    width: 61vw;
    margin: 2rem 0.5rem 1rem 0.5rem;
   text-align: start;
   background-color: transparent;
   border: 1px solid rgba(247, 208, 96, 1);
   border-radius: 1rem;
  
   padding: 1rem;
   font-size: 1.5rem;
   outline: none;
}

.name-email{
    display: flex;
    justify-content: start;
    align-items: start;
}

input{
    width: 30vw;
    margin: 2rem 0.5rem 0rem 0.5rem;
    border: none;
    background-color: transparent;
  border: 1px solid rgba(247, 208, 96, 0.6);
 border-radius: 1rem;
  
  padding: 1rem;
  font-size: 1.5rem;
  outline: none;
  color: white;
}

input:focus{
    width: 30vw;
   
    background-color: transparent;
  border: 1px solid rgba(247, 208, 96, 1);
  border-radius: 1rem;
 
  padding: 1rem;
  font-size: 1.5rem;
  outline: none;
}

.send-button{
    width: 30vw;
    height: 80px;
    border-radius: 0.5rem;
    background-color: #F7D060;
    font-size: 1.2rem;
    font-weight: bold;
}

.send-button:hover{
  background-color: #292826;
  color: #F7D060;
  border: solid 2px #F7D060;
  transition: all 0.3s ease;
  cursor: pointer;
}

.other-method{
    width: 50vw;
    height: 6vw;
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: absolute;
    bottom: -6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.other-method p{
    color: white;
}

.contact-buttons p{
    font-size: 1.2rem;
    color: #F7D060;
    margin-right: 0.5rem;
}

.contact-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }



  @media (max-width: 580px) {
  
    
    .contact-body h1{
      margin-bottom: 2rem;
      color: #F7D060;
    }
    .contact-form {
      width: 95vw;
      height: 60vh;
       display: flex;
       flex-direction: column;
       justify-content: start;
       align-items: center;
      border-radius: 2rem;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 1rem;
      position: relative;
    }
    
    #message{
        width: 80vw;
        margin: 2rem 0rem 1rem 0rem;
        height: 100%;
        border: none;
        background-color: transparent;
      border: 1px solid rgba(247, 208, 96, 0.6);
     border-radius: 1rem;
      
      padding: 1rem;
      font-size: 1rem;
      outline: none;
      color: white;
    }
    
    #message:focus{
        width: 80vw;
        margin: 2rem 0rem 1rem 0rem;
        height: 100%;
       text-align: start;
       background-color: transparent;
       border: 1px solid rgba(247, 208, 96, 1);
       border-radius: 1rem;
      
       padding: 1rem;
       font-size: 1rem;
       outline: none;
    }
    
    .name-email{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
    
    input{
        width: 80vw;
        margin: 2rem 0rem 0rem 0rem;
        border: none;
        background-color: transparent;
      border: 1px solid rgba(247, 208, 96, 0.6);
     border-radius: 1rem;
      
      padding: 1rem;
      font-size: 1rem;
      outline: none;
      color: white;
    }
    
    input:focus{
      width: 80vw;
      margin: 2rem 0rem 0rem 0rem;
      border: none;
      background-color: transparent;
    border: 1px solid rgba(247, 208, 96, 1);
   border-radius: 1rem;
    
    padding: 1rem;
    font-size: 1rem;
      outline: none;
    }
    
    .send-button{
        width: 70vw;
        height: 100px;
        border-radius: 0.5rem;
        background-color: #F7D060;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    .other-method{
        width: 70vw;
        height: 18vw;
        background-color: rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        position: absolute;
        bottom: -18vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .other-method p{
        color: white;
        
    }
    
    .contact-buttons p{
        font-size: 1.2rem;
        color: #F7D060;
        margin-right: 0.5rem;
    }
    
    .contact-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2rem;
    }
  
  }  
  
  @media (min-width: 580px)and(max-width:1150px){
    .other-method{
      width: 50vw;
      height: 9vw;
      background-color: rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      position: absolute;
      bottom: -9vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .contact-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}
  }
.home-top{
   width: 100%;
   height: 100vh;
  
   /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
   url(/src/images/background.jpg); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
    background-color: #292826;
    
}

.fiverr-icon-home{
  height: 5rem;
  width: 5rem;
 transition: transform 0.3s ease;
}

.fiverr-icon-home:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.spinner{
  height: 100vh;
  width: 100%;
  background-color: #292826;
  position: absolute;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

}
.home-title{
  display: flex;

}

.static-txt{
  font-size: 2.5em;
  color: white;
  margin-right: 15px;
  z-index: 90;
  margin-top: 0.5rem;
 
}

.dynamic-txt{
  font-size: 2.5em;
  color: #F7D060;
  margin-right: 15px;
  z-index: 90;
   margin-top: 0.5rem;
}



.profile-pic{
    height: 30vh;
    border-radius: 15vh;
    border: solid 3px #F7D060;
   
}





/* -------------------- */

.icon{
  margin-right: 20px;
 
  transition: transform 0.3s ease;
}

.icon:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.icon-container{
  margin-top: 1em;

  align-items: flex-end;
  display: flex;
}

.name{
  color: white;
  font-size: 1.5rem;
  margin-top: 1rem;
}

@media (max-width: 580px) {
  
  .static-txt{
    font-size: 1.5em;
    color: white;
    margin-right: 15px;
    z-index: 90;
    margin-top: 0.5rem;
   
  }
  
  .dynamic-txt{
    font-size: 1.5em;
    color: #F7D060;
    margin-right: 15px;
    z-index: 90;
     margin-top: 0.5rem;
  }
  
  
  
  .profile-pic{
      height: 25vh;
      border-radius: 15vh;
      border: solid 3px #F7D060;
     
  }
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.2); */
    z-index:1;
  }
  
  
  /* -------------------- */
  
  .icon{
    margin-right: 20px;
    margin-top: 20px;
  }
  
  .icon-container{
    margin-top: 1em;
  }
  
  .name{
    color: white;
    font-size: 1.0rem;
    margin-top: 1rem;
  }
  

}  


.project-body{
    width: 100%;
    
    background-color: #292826;
  
   display: flex;
   align-items: start;
   justify-content: center;
   flex-wrap: wrap;
   padding-top: 10vh;
   padding-left: 13vw;
   padding-right:13vw;
   perspective: 900px;
}
.top{
    background-color:#292826 ;
    width: 100%;
   
   
}

.project-title{
    font-size: 1.7rem;
   
  
    color:white ;
    padding-top: 17vh;
   
    text-align: center;
}



.container{
    min-width: 330px;
    min-height: 360px;

    width: 22vw;
    height:28vw ;
    margin-bottom: 2rem;
}

.container:hover > .project-container{
    cursor: pointer;
    transform: rotateY(180deg);
    
}

.project-container{
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform 800ms;
    transform-style: preserve-3d;
    
}

.front,.back{
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
    position: absolute;
    backface-visibility: hidden;
    border: solid 1px #F7D060;
}

.project-card-github{
    color: #F7D060;
}
.project-card-github:hover{
    color: #292826;
   border-radius: 300px;
   border: solid 1px #F7D060;
    background-color:  #F7D060;
    transition: color 0.5s ease;
}

.front-title{
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translate(-50%, -15%);
    color: white;
    z-index: 100;
    text-align: center;
    font-size: 1.2rem;
}

/* .front{
    background-image: url(/src/images/Screenshot_20221020_205646_com.example.lucky_touch.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
} */

.back{
    background-color: rgba(0, 0, 0,0.5);
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
padding: 1rem;
color: white;
font-weight: 100;
text-align: center;
z-index: 105;
}

.front::after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 80px;
    height: 6vw;
    background-color: rgba(0, 0, 0,0.9);
    border-bottom-left-radius:  2rem;
    border-bottom-right-radius:  2rem;
    text-align: center;
    
}





.tech-stacks{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.tech-stack{
   
    font-size: 1rem;
    font-weight: 600;
}

.more-btn{
 margin-left: 1rem;
 padding: 0.5rem ;
 background-color: #F7D060;
 border-radius: 10px;
 font-weight: bold;
   width: 6rem;
   color: black;
   text-align: center;
   
}

.project-description{
    padding-bottom: 2rem;
}

.more-btn:hover{
    background-color: #292826;
    color: #F7D060;
    border: solid 1px #F7D060;
    transition: all 0.5s ease;
}

.buttons{
    position: absolute;
    bottom: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}



@media (max-width: 580px) {

    .top{
        background-color:#292826 ;
        width: 100%;
       
    }

    .project-body{
        width: 100%;
        
        background-color: #292826;
      flex-direction: column;
       display: flex;
       flex-wrap: wrap;
       align-items: center;
       justify-content: center;
       flex-wrap: wrap;
       padding-top: 10vh;
       padding-left: 1rem;
       padding-right:1rem;
       perspective: 900px;
    }
    .project-title{
        font-size: 1.4rem;
       
      
        color:white ;
        padding-top: 14vh;
       
        text-align: center;
    }
    
    
    
    .container{
        width: 89vw;
        height:60vh ;
        margin-bottom: 2rem;
    }
    
    .container:hover > .project-container{
        cursor: pointer;
        transform: rotateY(180deg);
        
    }
    
    .project-container{
        height: 100%;
        width: 100%;
        position: relative;
        transition: transform 800ms;
        transform-style: preserve-3d;
    }
    
    .front,.back{
        height: 100%;
        width: 100%;
        border-radius: 2rem;
        box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
        position: absolute;
        backface-visibility: hidden;
        border: solid 1px #F7D060;
    }
    
    .front-title{
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translate(-50%, -15%);
        color: white;
       
        text-align: center;
        font-size:1.3rem;
        
    }
    

    .back{
        background-color: rgba(0, 0, 0,0.5);
        transform: rotateY(180deg);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    padding: 1rem;
    color: white;
    font-weight: 100;
    text-align: center;
    }
    
    .front::after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20vh;
        background-color: rgba(0, 0, 0,0.9);
        border-bottom-left-radius:  2rem;
        border-bottom-right-radius:  2rem;
        text-align: center;
        
    }
    
    
    
    
    
    .tech-stacks{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: start;
        align-items: start;
        flex-wrap: wrap;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
    }
    
    .tech-stack{
       
        font-size: 1rem;
        font-weight: 600;
    }
    
    .more-btn{
     margin-left: 1rem;
     padding: 0.5rem ;
     background-color: #F7D060;
     border-radius: 10px;
     font-weight: bold;
       width: 6rem;
       color: black;
       text-align: center;
       
    }
    
    .buttons{
        position: absolute;
        bottom: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
  } 


  